export const getClubWineTeam = async ($axios) => {
  try {
    return (
      await $axios.get('/api/catalog/oenologists/', {
        params: { 'club-page': true },
      })
    ).data.results
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCollectorsClubWineTeam = async ($axios) => {
  try {
    return (
      await $axios.get('/api/catalog/oenologists/', {
        params: { 'collectors-club-page': true },
      })
    ).data.results
  } catch (e) {
    console.error(e)
    throw e
  }
}
