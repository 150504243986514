<template>
  <section :class="sectionClass">
    <div class="site-width">
      <SectionHeading title="Curated by Unrivaled Experts" is-flush>
        <template #titleSlot>
          <h2>
            Curated by
            <br />
            Unrivaled Experts
          </h2>
        </template>
      </SectionHeading>
      <CardCarousel
        id="expert-carousel"
        :item-count="wineTeam.length"
        hide-pagination
        hide-arrows
        extra-classes="card_carousel__experts"
        :one-fourth-width-card="true"
      >
        <li v-for="oenologist in wineTeam" :key="oenologist.id">
          <span class="image-wrap">
            <span class="image card__image">
              <picture class="lazyLoad">
                <img
                  :alt="`Portrait of ${oenologist.name}`"
                  role="presentation"
                  class="lazyLoad"
                  v-lazy-load :data-src="oenologist.photo_image"
                />
              </picture>
            </span>
          </span>
          <h3 class="card__title">
            {{ oenologist.name }}
          </h3>
          <h4>
            {{ oenologist.expertise }}
          </h4>
          <p class="description" v-html="oenologist.biography"></p>
        </li>
      </CardCarousel>
    </div>
  </section>
</template>

<script>
import { getClubWineTeam } from '~/../common/services/experts'

export default {
  name: 'ExpertsSection',
  components: {
    CardCarousel: () => import('~/components/CardCarousel'),
    SectionHeading: () => import('~/components/SectionHeading'),
  },
  props: {
    extraClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      wineTeam: [],
    }
  },
  async fetch() {
    this.wineTeam = await getClubWineTeam(this.$axios)
  },
  computed: {
    sectionClass() {
      return `experts ${this.extraClasses}`
    },
  },
}
</script>

<style scoped></style>
